.pixel-borders {
  position: relative;
  display: inline-block;
  margin: 0 15px 15px 0;
  padding: 15px 20px;
  background-color: #fff;
  font-family: "Press Start 2P", Arial Black, Arial Bold, Arial, sans-serif;
}

.pixel-borders--1 {
  border-style: solid;
  border-width: 4px;
  border-color: #000;
  border-image-slice: 2;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-source: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6'><path d='M0 2h2v2H0zM2 0h2v2H2zM4 2h2v2H4zM2 4h2v2H2z' fill='%23000' /></svg>");
}

@supports (border-image-source: none) {
  .pixel-borders--1 {
    border-radius: 14px;
  }
}

.pixel-borders--1-inset {
  border-style: solid;
  border-width: 4px;
  border-color: #000;
  border-image-slice: 2;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-source: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6'><path d='M0 2h2v2H0zM2 0h2v2H2zM4 2h2v2H4zM2 4h2v2H2z' fill='%23000' /></svg>");
  position: relative;
}

@supports (border-image-source: none) {
  .pixel-borders--1-inset {
    border-radius: 14px;
  }
}

.pixel-borders--1-inset::after {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset -4px -4px #ddd;
}

.pixel-borders--2 {
  border-style: solid;
  border-width: 4px;
  border-color: #000;
  border-image-slice: 4;
  border-image-width: 2;
  border-image-outset: 0;
  border-image-source: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12'><path d='M2 2h2v2H2zM4 0h2v2H4zM10 4h2v2h-2zM0 4h2v2H0zM6 0h2v2H6zM8 2h2v2H8zM8 8h2v2H8zM6 10h2v2H6zM0 6h2v2H0zM10 6h2v2h-2zM4 10h2v2H4zM2 8h2v2H2z' fill='%23000' /></svg>");
}

@supports (border-image-source: none) {
  .pixel-borders--2 {
    border-radius: 20px;
  }
}

.pixel-borders--2-inset {
  border-style: solid;
  border-width: 4px;
  border-color: #000;
  border-image-slice: 4;
  border-image-width: 2;
  border-image-outset: 0;
  border-image-source: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12'><path d='M2 2h2v2H2zM4 0h2v2H4zM10 4h2v2h-2zM0 4h2v2H0zM6 0h2v2H6zM8 2h2v2H8zM8 8h2v2H8zM6 10h2v2H6zM0 6h2v2H0zM10 6h2v2h-2zM4 10h2v2H4zM2 8h2v2H2z' fill='%23000' /></svg>");
}

@supports (border-image-source: none) {
  .pixel-borders--2-inset {
    border-radius: 20px;
  }
}

.pixel-borders--2-inset::after {
  border-style: solid;
  border-width: 4px;
  border-color: #ddd;
  border-image-slice: 2;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-source: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6'><path d='M0 2h2v2H0zM2 0h2v2H2zM4 2h2v2H4zM2 4h2v2H2z' fill='%23ddd' /></svg>");
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  border-image-width: 0 1 1 0;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
}

@supports (border-image-source: none) {
  .pixel-borders--2-inset::after {
    border-radius: 14px;
  }
}

.pixel-box--light {
  border-style: solid;
  border-width: 4px;
  border-color: #cccccc;
  border-image-slice: 2;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-source: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6'><path d='M0 2h2v2H0zM2 0h2v2H2zM4 2h2v2H4zM2 4h2v2H2z' fill='%23cccccc' /></svg>");
  position: relative;
  background-color: #fff;
}

@supports (border-image-source: none) {
  .pixel-box--light {
    border-radius: 14px;
  }
}

.pixel-box--light::after {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset -4px -4px #e6e5e5;
}

.pixel-box--light:hover, .pixel-box--light:focus {
  background-color: #f2f2f2;
}

.pixel-box--primary {
  color: #fff;
  border-style: solid;
  border-width: 4px;
  border-color: #0c639c;
  border-image-slice: 2;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-source: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6'><path d='M0 2h2v2H0zM2 0h2v2H2zM4 2h2v2H4zM2 4h2v2H2z' fill='%230c639c' /></svg>");
  position: relative;
  background-color: #209cee;
}

@supports (border-image-source: none) {
  .pixel-box--primary {
    border-radius: 14px;
  }
}

.pixel-box--primary::after {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset -4px -4px #1081cb;
}

.pixel-box--primary:hover, .pixel-box--primary:focus {
  background-color: #1190e3;
}

.pixel-box--success {
  color: #fff;
  border-style: solid;
  border-width: 4px;
  border-color: #5b8423;
  border-image-slice: 2;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-source: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6'><path d='M0 2h2v2H0zM2 0h2v2H2zM4 2h2v2H4zM2 4h2v2H2z' fill='%235b8423' /></svg>");
  position: relative;
  background-color: #92cc41;
}

@supports (border-image-source: none) {
  .pixel-box--success {
    border-radius: 14px;
  }
}

.pixel-box--success::after {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset -4px -4px #77ac2e;
}

.pixel-box--success:hover, .pixel-box--success:focus {
  background-color: #85c034;
}

.pixel-box--warning {
  border-style: solid;
  border-width: 4px;
  border-color: #a88f06;
  border-image-slice: 2;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-source: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6'><path d='M0 2h2v2H0zM2 0h2v2H2zM4 2h2v2H4zM2 4h2v2H2z' fill='%23a88f06' /></svg>");
  position: relative;
  background-color: #f7d51d;
}

@supports (border-image-source: none) {
  .pixel-box--warning {
    border-radius: 14px;
  }
}

.pixel-box--warning::after {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset -4px -4px #d9b908;
}

.pixel-box--warning:hover, .pixel-box--warning:focus {
  background-color: #f2ce09;
}

.pixel-box--error {
  color: #fff;
  border-style: solid;
  border-width: 4px;
  border-color: #bc361a;
  border-image-slice: 2;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-source: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6'><path d='M0 2h2v2H0zM2 0h2v2H2zM4 2h2v2H4zM2 4h2v2H2z' fill='%23bc361a' /></svg>");
  position: relative;
  background-color: #e76e55;
}

@supports (border-image-source: none) {
  .pixel-box--error {
    border-radius: 14px;
  }
}

.pixel-box--error::after {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset -4px -4px #e14828;
}

.pixel-box--error:hover, .pixel-box--error:focus {
  background-color: #e45b3f;
}

.pixel-box--light-custom {
  border-style: solid;
  border-width: 4px;
  border-color: #cccccc;
  border-image-slice: 2;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-source: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6'><path d='M0 2h2v2H0zM2 0h2v2H2zM4 2h2v2H4zM2 4h2v2H2z' fill='%23cccccc' /></svg>");
  position: relative;
  background-color: #fff;
}

@supports (border-image-source: none) {
  .pixel-box--light-custom {
    border-radius: 14px;
  }
}

.pixel-box--light-custom::after {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset -4px -4px #999, inset 4px 4px #999;
}

.pixel-box--light-custom:hover, .pixel-box--light-custom:focus {
  background-color: #f2f2f2;
}

.pixel-box--primary-custom {
  color: #fff;
  border-style: solid;
  border-width: 4px;
  border-color: #0c639c;
  border-image-slice: 2;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-source: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6'><path d='M0 2h2v2H0zM2 0h2v2H2zM4 2h2v2H4zM2 4h2v2H2z' fill='%230c639c' /></svg>");
  background-color: #209cee;
}

@supports (border-image-source: none) {
  .pixel-box--primary-custom {
    border-radius: 14px;
  }
}

.pixel-box--primary-custom:hover, .pixel-box--primary-custom:focus {
  background-color: #1190e3;
}

.pixel-box--success-custom {
  color: #fff;
  border-style: solid;
  border-width: 4px;
  border-color: #5b8423;
  border-image-slice: 2;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-source: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6'><path d='M0 2h2v2H0zM2 0h2v2H2zM4 2h2v2H4zM2 4h2v2H2z' fill='%235b8423' /></svg>");
  position: relative;
  background-color: #92cc41;
}

@supports (border-image-source: none) {
  .pixel-box--success-custom {
    border-radius: 14px;
  }
}

.pixel-box--success-custom::after {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 4px 4px #b5dc7d;
}

.pixel-box--success-custom:hover, .pixel-box--success-custom:focus {
  background-color: #85c034;
}

.pixel-box--warning-custom {
  border-style: solid;
  border-width: 4px;
  border-color: #a88f06;
  border-image-slice: 2;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-source: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6'><path d='M0 2h2v2H0zM2 0h2v2H2zM4 2h2v2H4zM2 4h2v2H2z' fill='%23a88f06' /></svg>");
  position: relative;
  background-color: #f7d51d;
}

@supports (border-image-source: none) {
  .pixel-box--warning-custom {
    border-radius: 14px;
  }
}

.pixel-box--warning-custom::after {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset -4px -4px #d9b908, inset 4px 4px #fae367;
}

.pixel-box--warning-custom:hover, .pixel-box--warning-custom:focus {
  background-color: #f2ce09;
}

.pixel-box--error-custom {
  color: #fff;
  border-style: solid;
  border-width: 4px;
  border-color: #000;
  border-image-slice: 2;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-source: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6'><path d='M0 2h2v2H0zM2 0h2v2H2zM4 2h2v2H4zM2 4h2v2H2z' fill='%23000' /></svg>");
  position: relative;
  background-color: #e76e55;
}

@supports (border-image-source: none) {
  .pixel-box--error-custom {
    border-radius: 14px;
  }
}

.pixel-box--error-custom::after {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset -4px -4px #e14828;
}

.pixel-box--error-custom:hover, .pixel-box--error-custom:focus {
  background-color: #e45b3f;
}
